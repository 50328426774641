import React, { useCallback, useState } from 'react';

import { Disclosure } from '@headlessui/react';
import { SearchIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { useRouter } from 'next/router';

import Logo from '../assets/icons/exodia.svg';
import { Button } from './Button';
import { MetaMask } from './v1/MetaMaskConnectV1';
import { TopBarSearch } from './TopBarSearch';
import { Select } from './Select';

export interface NavProps {}
type NavLink = { label: string; href: string; external?: boolean };

export const Nav: React.FC<NavProps> = props => {
  const router = useRouter();
  const [showSearchBar, setShowSearchBar] = useState(false);

  const links: Array<NavLink | { label: string; options: Array<NavLink> }> = React.useMemo(
    () => [
      { label: 'Dashboard', href: '/dashboard' },
      { label: 'Mints', href: '/mints' },
      { label: 'Sniper', href: '/events/live' },
      { label: 'Discord', href: 'https://discord.gg/exodia-io', external: true },
    ],
    []
  );

  const logo = (
    <Link href="/" prefetch={false}>
      <a>
        <Logo className="h-8" />
      </a>
    </Link>
  );

  const renderDesktopMenuItem = useCallback(
    (link: NavLink | { label: string; options: Array<NavLink> }) => {
      if ('options' in link) {
        return (
          <Select<string>
            list={link.options.map(option => ({ name: option.label, value: option.href }))}
            onChange={({ value }) => {
              value && router.push(value);
            }}
            value={{ name: 'Indexes' }}
            width="sm"
            hideBorder
          />
        );
      }

      return link.external ? (
        <a href={link.href} target="_blank" rel="noreferrer">
          {link.label}
        </a>
      ) : (
        <Link href={link.href} prefetch={false}>
          {link.label}
        </Link>
      );
    },
    [router]
  );

  const renderMobileMenuItem = useCallback(
    (link: NavLink | { label: string; options: Array<NavLink> }) => {
      if ('options' in link) {
        return (
          <div className="border-b border-gray-300 dark:border-gray-750">
            <Select<string>
              list={link.options.map(option => ({ name: option.label, value: option.href }))}
              onChange={({ value }) => {
                value && router.push(value);
              }}
              value={{ name: 'Indexes' }}
              size="sm"
              hideBorder
              hideOptionsBorder
              relativeOptions
            />
          </div>
        );
      }

      return link.external ? (
        <a
          href={link.href}
          className="block px-3 py-2 border-b border-gray-300 dark:border-gray-750"
          target="_blank"
          rel="noreferrer"
        >
          {link.label}
        </a>
      ) : (
        <Link href={link.href} prefetch={false}>
          <a className="block px-3 py-2 border-b border-gray-300 dark:border-gray-750">
            {link.label}
          </a>
        </Link>
      );
    },
    [router]
  );

  const desktopMenuList = (
    <ul className="flex flex-1 items-center justify-center ml-8">
      {links.map(link => {
        return (
          <li key={link.label} className="font-grifter text-center px-4 hover:opacity-60">
            {renderDesktopMenuItem(link)}
          </li>
        );
      })}
    </ul>
  );

  const desktopMenuRight = (
    <div className="flex">
      <div className="hidden sm:flex items-center">
        <MetaMask />
        {/* {hasAccess ? (
          <div className="font-grifter text-lg">PREMIUM ACCOUNT</div>
        ) : (
          <Button color="gradient-v2" size="lg" asLink href="/#pricing">
            <span>Lifetime Access</span>
          </Button>
        )} */}
      </div>
    </div>
  );

  const mobileMenu = (
    <>
      <ul className="my-3 px-2">
        {links.map(link => {
          return (
            <li
              key={link.label}
              className="font-grifter rounded-md text-base font-medium text-gray-500 hover:text-black hover:bg-gray-300 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-750"
            >
              {renderMobileMenuItem(link)}
            </li>
          );
        })}
      </ul>
    </>
  );

  const navCenter = (
    <>
      {showSearchBar && (
        <div className="hidden md:block w-full">
          <TopBarSearch setShowSearchBar={setShowSearchBar} />
        </div>
      )}
      {!showSearchBar && (
        <div className="flex items-center">
          <div className="hidden xl:block">{desktopMenuList}</div>
          {/* <div className="flex space-x-6 justify-between ml-4 mr-6">
            <Button
              link
              color="transparent"
              size="xl"
              onClick={() => {
                setShowSearchBar(true);
              }}
            >
              <SearchIcon className="h-5 w-5" />
            </Button>
          </div> */}
        </div>
      )}
    </>
  );

  const renderNavRight = (open: boolean) => (
    <>
      <div className="hidden xl:block">{desktopMenuRight}</div>
      <Disclosure.Button className="inline-flex xl:hidden justify-center rounded-md hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none transition duration-200 ease">
        <span className="sr-only">Open main menu</span>
        {open ? (
          <svg
            className="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        ) : (
          <svg
            className="block h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        )}
      </Disclosure.Button>
    </>
  );

  return (
    <Disclosure as="nav" className="relative">
      {({ open }) => (
        <>
          <div className="flex mt-8 items-center">
            <div className="flex items-center h-20">{logo}</div>
            <div className="flex items-center w-full justify-end">
              <div className="mr-8">{navCenter}</div>
              {renderNavRight(open)}
            </div>
            {showSearchBar && (
              <div className="block md:hidden w-full absolute top-20">
                <TopBarSearch setShowSearchBar={setShowSearchBar} />
              </div>
            )}
          </div>
          <Disclosure.Panel className="xl:hidden absolute right-0 rounded top-16 w-full bg-gray-100 dark:bg-gray-850 z-40 max-w-96">
            {mobileMenu}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
