export function priceChange(options: {
  previous?: number | null;
  current?: number | null;
}): number | undefined {
  const { previous, current } = options;

  if (!previous || !current) return;

  const change = ((current - previous) / previous) * 100;
  return change;
}

export function formatPercentChange(change: number, showAsAsoluteNumber = false): string {
  const percent = `${(showAsAsoluteNumber ? Math.abs(change) : change).toFixed(1)}%`;

  if (change > 0 && !showAsAsoluteNumber) return `+${percent}`;
  return percent;
}

export const ETH_SYMBOL = 'Ξ';
export const formatEthPrice = (price?: number, decimals?: number): string => {
  if (typeof price === 'undefined' || price === null) return '';
  if (isNaN(price)) return '';

  return `${
    price && typeof decimals !== 'undefined' ? price.toFixed(decimals) : price
  }${ETH_SYMBOL}`;
};
