import { useWeb3Context } from '@utils/Web3Context';
import React, { useEffect } from 'react';

import { Footer } from '../Footer';
import { Nav } from '../Nav';

interface LayoutProps {}

export const Layout: React.FC<LayoutProps> = props => {
  const { checkIfWalletConnected } = useWeb3Context();

  useEffect(() => {
    checkIfWalletConnected();
  }, []);

  return (
    <div className="bg-white text-black dark:bg-black dark:text-white px-8 sm:px-12 md:px-24 lg:px-28 max-w-screen-2xl mx-auto pb-12">
      <Nav />
      {props.children}
      <div className="mt-8">
        <Footer />
      </div>
    </div>
  );
};
