import React, { ChangeEvent, KeyboardEvent, MutableRefObject, useCallback } from 'react';
import { SearchIcon, XIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import { Button, FullButtonProps } from '@components/Button';

export interface InputProps {
  label?: string;
  showSearchIcon?: boolean;
  register?: any; // TODO
  error?: string;
  buttonProps?: FullButtonProps;
  forwardedRef?: MutableRefObject<HTMLInputElement | undefined>;
}

export const Input: React.FC<
  InputProps &
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
> = props => {
  const {
    label,
    onKeyDown,
    showSearchIcon,
    register,
    name,
    error,
    forwardedRef,
    buttonProps,
    ...inputProps
  } = props;
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown && onKeyDown(e);
      if (e.key === 'Escape') {
        forwardedRef?.current?.blur();
      }
    },
    [onKeyDown, forwardedRef]
  );

  return (
    <div className="relative">
      <label htmlFor={props.name} className="block text-sm font-medium text-gray-700 sr-only">
        {label || 'Label'}
      </label>
      <div>
        <div className={classNames('relative rounded-md shadow-sm flex items-stretch flex-grow')}>
          <input
            className={classNames(
              'flex-1 rounded-xl bg-transparent border-gray-300 dark:border-gray-750 focus:ring-primary-light-mode-light focus:border-primary-light-mode-light dark:focus:ring-secondary-dark-mode-main dark:focus:border-secondary-dark-mode-main block w-full py-4 sm:text-sm',
              {
                'pl-10': showSearchIcon,
                'pl-4': !showSearchIcon,
                'pr-4': !buttonProps,
                'pr-28': buttonProps,
              }
            )}
            type="text"
            id={props.name}
            ref={forwardedRef}
            onKeyDown={handleKeyDown}
            {...(register?.(name) || {})}
            {...inputProps}
          />
          {showSearchIcon && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <SearchIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
            </div>
          )}

          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {!!props.value && (
              <div
                className="pr-3 flex items-center"
                onMouseDown={e => e.preventDefault()}
                onClick={() => {
                  // clear search input
                  props.onChange &&
                    props.onChange({ target: { value: '' } } as ChangeEvent<HTMLInputElement>);
                }}
              >
                <XIcon
                  className="h-5 w-5 text-gray-600 cursor-pointer hover:text-gray-850 dark:hover:text-gray-400"
                  aria-hidden="true"
                  id="input-search"
                />
              </div>
            )}
            {buttonProps ? (
              <Button color="primary" disabled={!props.value} {...buttonProps} />
            ) : null}
          </div>
        </div>
        {error && <div className="absolute -bottom-5  text-sm text-red-error">{error}</div>}
      </div>
    </div>
  );
};
