import { logConnectAddress } from '@utils/amplitude';
import React from 'react';

import { shortAddress } from '@utils/address';
import { useWeb3Context } from '@utils/Web3Context';
import { Button } from '../Button';

export const MetaMask: React.FC<{}> = props => {
  const { address, hasPremium, onConnectWallet } = useWeb3Context();

  return (
    <div>
      {address ? (
        <Button color="transparent" size="lg" link>
          {hasPremium ? 'Premium' : 'Connected'}: {shortAddress(address)}
        </Button>
      ) : (
        <Button
          color="gradient-v2"
          rounded
          size="lg"
          onClick={() => {
            logConnectAddress();
            onConnectWallet();
          }}
        >
          <span>Connect Wallet</span>
        </Button>
      )}
    </div>
  );
};
