import React, { useCallback, ReactNode } from 'react';
import { Listbox } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

export type SelectOption<T> = { name: string; label?: string; value?: T; icon?: ReactNode };

export type SelectProps<T> = {
  value?: SelectOption<T>;
  onChange: (value: SelectOption<T>) => void;
  list: SelectOption<T>[];
  blankText?: string;
  size?: 'sm' | 'md';
  width?: 'sm' | 'md' | 'lg';
  hideBorder?: boolean;
  hideOptionsBorder?: boolean;
  relativeOptions?: boolean;
  toTop?: boolean;
  highlight?: boolean;
};

export function Select<T>(props: SelectProps<T>) {
  const {
    value,
    list,
    onChange,
    blankText = 'Select an option',
    size = 'md',
    width,
    hideBorder = false,
    hideOptionsBorder = false,
    relativeOptions = false,
    // works only for absolute options
    toTop = false,
    highlight = false,
  } = props;

  const renderIcon = useCallback(icon => {
    if (!icon) return null;

    const Icon = icon;
    return <Icon className="h-8 w-8 mr-4" />;
  }, []);

  return (
    <div
      className={classNames('relative w-full', {
        'w-32': width === 'sm',
        'w-64': width === 'md',
        'w-96': width === 'lg',
      })}
    >
      <Listbox value={value} onChange={onChange}>
        <div className="relative">
          <Listbox.Button
            className={classNames(
              'relative flex items-center font-grifter w-full pr-10 text-left rounded-lg cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-neon-green-light-mode dark:focus-visible:ring-neon-yellow-500 focus-visible:ring-offset-2',
              {
                'py-2 pl-3': size === 'sm',
                'py-3 pl-5': size === 'md',
                'border border-gray-300 dark:border-gray-750': !hideBorder && !highlight,
                'border border-primary-light-mode-main dark:border-secondary-dark-mode-main':
                  !hideBorder && highlight,
              }
            )}
          >
            {renderIcon(value?.icon)}
            <span className="block truncate pt-1">{value?.label || value?.name || blankText}</span>
            <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>

          <Listbox.Options
            className={classNames(
              'w-full overflow-auto ring-1 bg-gray-100 dark:bg-gray-850 ring-black ring-opacity-5 focus:outline-none z-10',
              {
                'mt-1 py-2': size !== 'sm',
                'border border-gray-300 dark:border-gray-750 rounded-md': !hideOptionsBorder,
                absolute: !relativeOptions,
                'bottom-full': toTop,
              }
            )}
          >
            {list.map(item => (
              <Listbox.Option
                key={item.name}
                value={item}
                className={({ selected, active }) =>
                  classNames(
                    'flex items-center cursor-pointer select-none relative border-b border-gray-300 dark:border-gray-750 last:border-b-0 text-left px-5',
                    {
                      'text-neon-blue-700 dark:text-neon-blue-500': selected,
                      'bg-gray-200 dark:bg-gray-800': active,
                    }
                  )
                }
              >
                <>
                  {renderIcon(item.icon)}
                  <div
                    className={classNames('font-grifter block truncate ', {
                      'pt-4 pb-3': size === 'md',
                      'py-2': size === 'sm',
                    })}
                  >
                    {item.label || item.name}
                  </div>
                </>
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    </div>
  );
}
