import { useRef, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { CollectionItem, WalletItem } from '../pages/api/search/[term]';
import { Input } from './Input';
import Verified from '../assets/icons/verified-opensea.svg';
import { ETH_SYMBOL } from '../utils/price';
import { useCollectionSearch } from '@utils/swr';
import { prepareVolume } from '@utils/volume';

export interface TopBarSearchProps {
  setShowSearchBar?: React.Dispatch<React.SetStateAction<boolean>>;
  fullWidth?: boolean;
  placeholder?: string;
  collectionRedirectBaseUrl?: string;
  walletRedirectBaseUrl?: string;
  autoFocus?: boolean;
}

export const TopBarSearch: React.FC<TopBarSearchProps> = props => {
  const {
    setShowSearchBar = () => {},
    fullWidth = false,
    placeholder,
    collectionRedirectBaseUrl = '/collection',
    walletRedirectBaseUrl = '/wallet',
    autoFocus = true,
  } = props;

  const inputRef = useRef<HTMLInputElement>();

  const [searchValue, setSearchValue] = useState('');

  const { data: searchResults, isValidating } = useCollectionSearch(searchValue);

  const { collections, wallets } = searchResults || {};

  const maxSearchListLength = 6;

  const collectionsOrWallets = collections?.length ? collections : wallets;

  const resultsList = collectionsOrWallets?.slice(0, maxSearchListLength) || [];
  const showResultsList = resultsList?.length > 0;

  return (
    <div className={classNames('flex flex-col flex-auto  relative', { 'md:mx-10': !fullWidth })}>
      <Input
        forwardedRef={inputRef}
        autoFocus={autoFocus}
        onBlur={() => {
          setSearchValue('');
          setShowSearchBar(false);
        }}
        value={searchValue}
        onChange={e => setSearchValue(e.target.value)}
        showSearchIcon
        placeholder={placeholder || 'Search for a collection name or wallet address'}
      />

      {searchValue && (
        <div
          className="absolute top-20 py-2 w-full bg-surface-light-mode dark:bg-surface-dark-mode z-50 rounded-xl border border-gray-300 dark:border-gray-700"
          onMouseDown={e => e.preventDefault()}
        >
          {showResultsList ? (
            resultsList.map((item, i) => (
              <MenuItem
                key={i}
                item={item}
                index={i}
                resultsList={resultsList}
                onClick={() => {
                  setShowSearchBar(false);
                  inputRef?.current?.blur();
                }}
                collectionRedirectBaseUrl={collectionRedirectBaseUrl}
                walletRedirectBaseUrl={walletRedirectBaseUrl}
              />
            ))
          ) : (
            <div className="px-5 py-4">
              {isValidating ? 'Loading...' : 'No collections or accounts found'}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

// type guard
const isCollectionItem = (item: CollectionItem | WalletItem): item is CollectionItem =>
  !!item && 'id' in item;

interface MenuItemProps {
  item: CollectionItem | WalletItem;
  index: number;
  resultsList: any[];
  onClick?: () => void;
  collectionRedirectBaseUrl?: string;
  walletRedirectBaseUrl?: string;
}

export const MenuItem: React.FC<MenuItemProps> = props => {
  const { item, index, resultsList, onClick, collectionRedirectBaseUrl, walletRedirectBaseUrl } =
    props;
  if (!item) return null;
  const itemOptions = isCollectionItem(item)
    ? {
        id: item.id,
        slug: null,
        imageUrl: item.imageUrl,
        name: item.name,
        href: `${collectionRedirectBaseUrl}/${item.slug}`,
        isVerified: item.safelistRequestStatus === 'verified',
        totalVolume: item.collectionStats?.[0]?.totalVolume || 0,
      }
    : {
        id: item.address,
        slug: item.username,
        imageUrl: null,
        name: item.address,
        href: `${walletRedirectBaseUrl}/${item.address}`,
      };

  return (
    <Link key={itemOptions.id} href={itemOptions.href} prefetch={false}>
      <a
        className="group flex px-5 hover:bg-gray-100 dark:hover:bg-gray-750 hover:text-neon-green-light-mode"
        onClick={onClick}
      >
        <div
          className={classNames(
            'flex justify-between items-center  border-b border-gray-200 dark:border-gray-700 py-4 space-x-4  w-full',
            { 'border-b-0': index === resultsList.length - 1 }
          )}
        >
          <div className="flex space-x-3 items-center w-full max-w-full">
            {itemOptions.imageUrl && (
              <Image
                className="rounded-full"
                src={itemOptions.imageUrl}
                alt={itemOptions.name}
                width={40}
                height={40}
              />
            )}
            <div className="flex flex-col w-full">
              <div className="flex space-x-3 items-center self-stretch">
                {itemOptions.isVerified && <Verified className="w-6 h-6 self-start" />}
                <span className="font-grifter text-xl  overflow-ellipsis  max-w-full  overflow-hidden mb-0">
                  {itemOptions.name}
                </span>
              </div>

              <span className=" text-gray-500 group-hover:text-black dark:group-hover:text-white mt-1 lg:mt-0 whitespace-nowrap">
                {`${prepareVolume(itemOptions.totalVolume || 0)}${ETH_SYMBOL} volume`}
              </span>
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};
